//color
$background-color: #111827;

$primary-color: #7c3aed;
$secondary-color: #ffffff;
$thirdary-color: #d6d3d1;

$primary-hover: #a78bfa;
$secondary-hover: #9ca3af;
$secondary-hover-opacity: #9ca3af55;

$selection-color: #a78bfa;
$container-background: #e2ccfb;
$table-hover: #7c3aed20;

@import url("https://fonts.googleapis.com/css2?family=Barlow:300, 400, 500, 600, 700, 800, 900, 1000");

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  height: 100%;
  max-width: 100vw;
  background-color: $background-color;
  font-family: "Barlow", sans-serif;
}

* {
  scroll-behavior: smooth;
}
*::selection {
  background-color: $selection-color;
}

a {
  color: $primary-color;
}
a:hover {
  color: $primary-hover
}

.gradient_text {
  background: linear-gradient(to right, #dd00f1, #7e49d4, #24225d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
