@use "../../App.scss";

#market {
  min-height: 40vh;
  width: 100vw;
  padding-bottom: 40px;

  .blocked {
    height: 10vh;
  }

  .title {
    color: App.$primary-color;
    font-size: 30px;
    font-weight: 600;
    margin: 0 0 0 30px;
    text-shadow: 1px 1px 2px App.$primary-hover;
  }

  .tableContainer {
    box-sizing: border-box;
    margin: 20px 25px 0 25px;

    .Title {
      color: App.$secondary-color;
      font-weight: 600;
      padding: 15px 5px 15px 5px;
      border-bottom: 1px solid App.$secondary-hover;

      .cell {
        font-size: 18px;
      }
    }
    .number {
      text-align: right;
      //   padding: 0 5% 0 0;
    }

    .tryagain {
      color: App.$secondary-color;
      width: 100vw;
      // text-align: center;
      display: flex;
      justify-content: center;
      font-size: 18px;
      margin: 30px 0 0 0;
      text-shadow: 1px 0.5px 1.2px App.$primary-hover;
    }

    .Body {
      .Row {
        padding: 22px 7px 15px 7px;
        box-sizing: border-box;
        border-bottom: 1px solid App.$secondary-hover;
        transition: all 0.12s linear;

        .rowCell {
          display: flex;
          align-items: center;

            .image {
              height: 40px;
              width: 40px;
              margin: 0 10px 0 0;
            }

            .logo {
              color: App.$secondary-color;
              font-size: 20px;
              font-weight: 600;
              margin: 0 7px 0 0;
            }

            .name {
              color: App.$thirdary-color;
              font-size: 12px;
              font-weight: 500;
            }

            .logo:hover, .name:hover {
              color: App.$primary-hover;
              transition: all 0.1s linear;
              text-decoration: underline; 
            }

          .price {
            width: 100%;
            text-align: right;
            color: App.$secondary-color;
            font-size: 17px;
          }

          .changes {
            width: 100%;
            text-align: right;
            font-size: 17px;
            font-weight: 400;
          }

          .marketCap {
            color: App.$secondary-color;
            width: 100%;
            text-align: right;
            font-size: 17px;
          }

          .arrow {
            width: 100%;
            text-align: right;
            font-size: 18px;
            color: white;
          }

          .arrow:hover {
            color: App.$primary-hover;
          }
        }
      }

      .Row:hover {
        background-color: App.$table-hover;
      }
    }

    .pagnition {
      display: flex;
      list-style: none;
      color: white;
      justify-content: right;

      li,
      .icon {
        padding: 6px 10px 6px 10px;
        margin: 1.5px;
        font-size: 16px;
        border-radius: 5px;
        cursor: pointer;
        transition: all 0.12s linear;
      }

      li:hover,
      .icon:hover {
        background-color: App.$secondary-hover-opacity;
      }

      .isSelected {
        background-color: App.$secondary-hover;
      }
      .isSelected:hover {
        background-color: App.$secondary-hover;
      }

      .dotdotdot {
        padding: 6px 0 6px 0;
        margin: 0;
        cursor: default;
      }
      .dotdotdot:hover {
        background: transparent;
      }
    }
  }
}

@media screen and (max-width: 770px) {
  #market {
    .tableContainer {
      .tryagain {
        justify-content: left;
        font-size: 12px;
        margin: 10px 0 0 10vw;
      }
      .Title {
        .cell {
          font-size: 12px;
        }
      }

      .Body {
        .Row {
          .rowCell {
            .image {
              width: 20px;
              height: 20px;
            }

            .logo {
              font-size: 12px;
            }

            .name {
              font-size: 8px;
            }

            .price,
            .changes,
            .marketCap {
              font-size: 12px;
            }
          }
        }
      }

      .pagnition {
        li,
        .icon {
          font-size: 14px;
        }
      }
    }
  }
}
