@use "../../App.scss";

#faq {
    min-height: 100vh;

    .blocked {
        height: 14vh;
    }

    .title {
        color: App.$primary-color;
        text-shadow: 0.5px 1px 2px App.$primary-hover;
        font-size: 30px;
        font-weight: 600;
        text-align: left;
        margin: 0 0 0 3vw;
    }

    .collapseContainer {
        margin: 30px 0 0 0;
        padding: 0 3vw 0 3vw;

        .collapse {
            margin: 15px 0 0 0;
        }
    }
}