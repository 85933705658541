@use "../../App.scss";

#exchanges {
  min-height: 35vh;
  width: 100vw;
  padding: 0 0 10vh 0;

  .blocked {
    height: 12vh;
  }

  .title {
    color: App.$primary-color;
    font-size: 30px;
    font-weight: 600;
    margin: 0 0 0 30px;
    text-shadow: 1px 1px 2px App.$primary-hover;
  }

  .exchangeContainer {
    margin: 60px 0 0 0;

    .tokenContainer {
      .selectSection {
        display: flex;
        align-items: center;
        width: auto;

        .logo {
          width: 40px;
        }

        .clickSection {
          display: flex;
          margin: 0 0 0 5px;
          padding: 5px 7px 5px 7px;
          border-radius: 8px;
          cursor: pointer;
          transition: all 0.12s linear;

          .symbol {
            font-weight: 600;
            color: App.$secondary-color;
            font-size: 18px;
          }

          .icon {
            color: App.$secondary-color;
            margin: 0 0 0 5px;
          }
        }

        .clickSection:hover {
          background-color: rgba(255, 255, 255, 0.127);
        }
      }

      .inputAmount {
        height: 45px;
        width: 80%;
        margin: 10px 0 0 0;
        padding: 8px;
        border-radius: 12px;
        font-size: 18px;
        font-weight: 500;
        border: none;
        background-color: App.$container-background;
        transition: all 0.12s linear;
        text-align: right;
      }

      .inputAmount:focus {
        box-shadow: 1.5px 2px 8px App.$primary-hover;
        outline: 1px solid App.$primary-color;
      }

      .currPrice {
        color: white;
        margin: 7px 0 0 2px;
      }
    }

    .middleChangeContainer {
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      transform: translate(-3.3vw, 5.5vh);

      .icon {
        font-size: 22px;
      }
    }

    .middleChangeContainerMobile {
      display: none;

      .icon {
        color: App.$secondary-color;
        font-size: 18px;
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.connectWalletContainer {
  padding: 10px 0 0 0;
  .connectWalletForm {
    margin: 10px 0 0 0;

    .inputTitle {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 550;
    }

    .inputBox {
      height: 25px;
      width: 100%;
      padding: 4px 4px 4px 10px;
      border-radius: 12px;
      font-size: 14px;
      font-weight: 500;
      border: none;
      background-color: App.$container-background;
      transition: all 0.12s linear;
    }

    .inputBox:focus {
      box-shadow: 1.5px 2px 8px App.$primary-hover;
      outline: 1px solid App.$primary-color;
    }
  }

  .errNoti {
    width: 100%;
    color: red;
    font-size: 15px;
    font-weight: 600;
    padding: 7px 0 0 8px;
    transition: all 0.12s linear;
  }

  .confirmConnectBtn {
    text-align: center;
    margin: 26px 0 0 0;
    padding: 8px;
    border-radius: 10px;
    background-color: App.$primary-color;
    cursor: pointer;
    transition: all 0.14s linear;
    border: none;
    color: white;
    font-weight: 600;
    font-size: 16px;
    width: 100%;
    text-align: center;
  }

  .confirmConnectBtn:hover {
    background-color: App.$primary-hover;
  }

  .note {
    margin: 20px 0 15px 0;
    font-size: 11px;
    color: App.$primary-hover;
  }

  .savedWalletNoty {
    margin: 5px 0 0 0;
    font-size: 12px;
    text-align: center;
    width: 100%;

    .fastconnect {
      color: App.$primary-color;
      font-weight: 600;
      cursor: pointer;
      transition: all 0.12s linear;
    }

    .fastconnect:hover {
      color: App.$primary-hover;
    }
  } 

  .demoWalletInfo {
    padding: 12px 0 0 0;
    border-top: 1px solid App.$secondary-hover;

    .title {
      font-size: 15px;
      font-weight: 600;
      margin: 0 0 3px 0;
    }
  }

  .disconnect {
    width: 100%;
  }
}

.viewHistory {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 5px 0 7px 0;

  .viewText {
    font-weight: 550;
    transition: all 0.12s linear;
    cursor: pointer;
    background: none;
    border: none;
    font-size: 14px;
    color: App.$secondary-color;
  }

  .viewText:hover {
    color: App.$secondary-hover;
  }
  text-align: center;
}

.connectWallet {
  display: flex;
  align-items: center;
  justify-self: center;
  text-align: center;
  margin: 30px 0 0 0;
  padding: 10px;
  border-radius: 10px;
  background-color: App.$primary-color;
  cursor: pointer;
  transition: all 0.14s linear;

  .icon {
    margin: 0 8px 0 0;
  }

  .callToAction {
    width: 100%;
    text-align: center;
    font-weight: 550;
  }

  .exchangeBtn {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 1.5px 0 1.7px 0;
    font-size: 15px;
    font-weight: 600;
  }
}
.connectWallet:hover {
  background-color: App.$primary-hover;
}

.modalSelectTokenInput {
  height: 30px;
  width: 100%;
  padding: 8px 8px 8px 17px;
  border-radius: 12px;
  font-size: 15px;
  font-weight: 500;
  border: none;
  background-color: App.$container-background;
  transition: all 0.12s linear;
}

.modalSelectTokenInput:focus {
  box-shadow: 1px 1px 4px App.$primary-hover;
  outline: 1px solid App.$primary-color;
}

.tokenTableContainer {
  margin: 10px 0 0 0;
  overflow: scroll;
  height: 450px;

  .assetsTokenContainer {
    height: 320px;
    overflow: scroll;
  }

  .row {
    padding: 10px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    transition: all 0.12s linear;
    cursor: pointer;

    .logo {
      height: 30px;
    }

    .infoContainer {
      display: flex;
      align-items: center;

      .info {
        display: block;
        margin: 0 0 0 8px;

        .symbol {
          font-size: 14px;
          font-weight: 600;
        }
        .name {
          font-size: 10px;
        }
      }
    }

    .arrow {
      text-align: right;
    }

    .timestamp {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 0 0 3px 0;
      font-weight: 600;
      color: App.$secondary-color;
    }
  }

  .row:hover {
    background-color: App.$table-hover;
  }

  .WallAddrContainer {
    padding: 5px 0 10px 15px;
    font-weight: 550;
    font-size: 15px;

    .WallAddr {
      padding: 0 0 0 10px;
      font-size: 14px;
      font-weight: 400;
    }
  }

  
}

.exchangeContainerSection {
  .viewAssetsBtn {
    display: flex;
    align-items: center;
    justify-self: center;
    text-align: center;
    margin: 5px 0 0 0;
    padding: 10px;
    color: App.$secondary-color;
    border-radius: 10px;
    font-weight: 550;
    cursor: pointer;
    transition: all 0.14s linear;

    .exchangeBtn {
      width: 100%;
      text-align: center;
    }
  }

  .viewAssetsBtn:hover {
    background-color: rgba(255, 255, 255, 0.225);
  }
}

@media screen and (max-width: 768px) {
  #exchanges {
    .exchangeContainer {
      .tokenContainer {
        width: 100%;
        justify-content: center;
        margin-bottom: 15px;

        .inputAmount {
          font-size: 16px;
          height: 32px;
        }

        .selectSection {
          .logo {
            width: 30px;
          }

          .symbol {
            font-size: 16px;
          }

          .currPrice {
            font-size: 12px;
          }
        }
      }

      .middleChangeContainer {
        display: none;
      }

      .middleChangeContainerMobile {
        padding-left: 6.5vw;
        display: block;
        box-sizing: border-box;
      }
    }

    .connectWallet {
      color: white;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        display: flex;
      }
    }
  }
  .exchangeContainerSection {
    .viewAssetsBtn {
      width: 100%;
    }
  }
}
