@use "../../App.scss";

.coinInfo {
  width: 100vw;
  min-height: 150vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px;


  .graphContainer {
    width: 100vw;
    display: flex;
    justify-content: center;

    box-sizing: border-box;

    .displayGraph {
      width: 80vw;
      padding: 0 2vw 0 2vw;
    }
  }
  .graphTitle {
    margin: 20px 0 10px 0;
    padding: 10px 2vw 0 2vw;
    color: App.$primary-color;
    text-shadow: 0.5px 1px 2px App.$primary-hover;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
  }

  .imageContainer {
    text-align: center;
    .icon {
      width: 200px;
    }

    .name {
      color: white;
      font-weight: 550;
      font-size: 35px;
      margin: 15px 0 20px 0;
    }

    .rank {
      color: white;
      font-size: 20px;

      span {
        font-weight: 600;
        color: App.$primary-color;
        text-shadow: 0.7px 0.5px 1px App.$primary-hover;
      }
    }
  }

  .changes,
  .price,
  .coinSymbol {
    margin: 15px 0 0 0;
    width: 100%;
    text-align: center;
    color: white;
    font-size: 18px;
  }

  .description {
    margin: 25px 18px 0 10px;
    padding: 0 10px 0 0;
    font-size: 16px;
    color: white;
    height: 250px;
    width: 100%;
    overflow: scroll;
    letter-spacing: 0.4px;
    line-height: 21px;
    box-sizing: border-box;

    // Custom scrollbar styles
    &::-webkit-scrollbar {
      width: 7px; // width of the scrollbar
      height: 0px;
    }

    &::-webkit-scrollbar-track {
      background: App.$secondary-hover; // color of the track
    }

    &::-webkit-scrollbar-thumb {
      background: App.$primary-color; // color of the scroll thumb
      transition: all 0.2 linear;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: App.$primary-hover; // color of the scroll thumb when hovering
      transition: all 0.2 linear;
    }

    a {
      color: App.$primary-color;
    }
    a:hover {
      color: App.$primary-hover;
    }
  }
}

@media screen and (max-width: 650px) {
  .coinInfo {
    .imageContainer {
      margin-top: 5vh;
      .icon {
        width: 150px;
      }
      .rank {
        margin: 25px 30px 20px 30px;
        padding: 0 0 25px 0;
        border-bottom: 1px solid white;
      }
    }

    .description {
      margin: 25px 20px 0 25px;
      font-size: 14px;
    }
  }
}
